export const englishContent = {
  no_data_found: "No data found",
  no_data_found_add_data: 'Click "Add Button" to add data on screen ',
  amount: "Amount",
  all: "All",
  dashboard: "Dashboard",
  dharmshala: "Dharmshala",
  donation: "Donation",
  commitment: "Pledge",
  family: "Family",
  personal: "Personal",
  official: "Official",
  hundi: "Donation Box",
  financial_reports: "Financial Reports",
  internal_expenses: "Expenses",
  total_donation: "Total Donation :",
  events: "Events",
  total: "Total :",
  Select: "Select",
  news: "News",
  notices: "Notices",
  configuration: "Configuration",
  searchBarPlaceHolder: "Search Temples, Trust, Events, News",
  monthName_January: "January",
  monthName_February: "February",
  monthName_March: "March",
  monthName_April: "April",
  monthName_May: "May",
  monthName_June: "June",
  monthName_July: "July",
  monthName_August: "August",
  monthName_September: "September",
  monthName_October: "October",
  monthName_November: "November",
  monthName_December: "December",
  last_login: "Last Logged In",
  category: "Category",
  user: "Sub Admin",
  report_Dispute: "Report & Dispute",
  membership: "Members",
  masters: "Masters",
  custom_field: "Custom Field",
  masters_list: "Master List",
  cancel: "Cancel",
  confirm: "Confirm Delete",
  save_changes: "Save Changes",
  rupees: "Rs",
  downloadReceipt: "Download Receipt",
  pay_donation: "Pay Donation",
  payment: "Pay Now",
  paymentPaid: "Paid",
  injection_found: "Special characters are not supported",
  logout_msg: "Are you sure you want to logout?",
  no: "No",
  yes: "Yes",
  tags_limit: "Tags limit exceeded",
  end_time_less: "The End time must be greater than the start time",
  publish_now: "Publish Now",
  already_publish: "News is already published.",
  already_publish_notice: "Notice is already published.",
  unPublish: "Unpublish",
  schedule: "Schedule",
  reSchedule: "Re-schedule",
  publish: "Publish",
  published: "Published",
  invalid_amount: "Invalid value",
  tagsChar_limit:
    "The tag field has a character limit of 20 and cannot accept more than that.",
  // All static content of Dashboard
  dashboard_donationReceived: "Donation Received",
  dashboard_donationPending: "Donation Pending",
  dashboard_all: "All",
  donation_hundi: "Donation + Hundi",
  dashboard_totalExpenses: "Total Expenses",
  noTopDonar: "There are no ",
  dashboard_RevenueReport: "Finance Report",
  notification_not_found: "No Notification Found",
  dashboard_monthly: "This Month",
  dashboard_weekly: "This Week",
  dashboard_yearly: "This Year",
  dashboard_daily: "Daily",

  dashboard_card_title3: "Subscribed Users",
  dashboard_Recent_DonationCommitment: "Recent Donations",
  dashboard_Recent_DonorName: "Donor Name",
  dashboard_Recent_DonorNumber: "Mobile Number",
  dashboard_Recent_DonorType: "Type",
  dashboard_Recent_DonorDate: "Date & Time",
  dashboard_Recent_DonorStatus: "Status",
  dashboard_Recent_DonorAmount: "Amount",
  dashboard_Recent_DonorCommitId: "Pledge ID",
  dashboard_Recent_DonorReceipt: "Receipt",
  dashboard_viewAll: "View All",
  dashboard_top: "Top 5 Donor's",
  label_state: "State",
  label_country: "Country",
  label_city: "City",
  label_district: "District",
  label_pin: "PIN",
  label_pincode: "Pincode",
  label_googlemap: "GoogleMap",
  label_add1: "Address Line 1(House no)",
  label_add2: "Address Line 2",
  // All static content of News

  news_AddNews: "Add News",
  news_EditNews: "Edit News",
  news_AddLangNews: "Add Language",
  news_InputIn: "Select Language :",
  news_label_Title: "Title",
  news_label_Tags: "Tags",
  news_label_Description: "Add Description",
  news_label_Date: "Selected Date",
  news_label_Published: "Published by",
  news_label_ImageVedio: "Add Images",
  news_button_Publish: "Publish",
  news_title_required: "Title is required",
  news_tags_required: "Tags are required",
  news_desc_required: "Description is required",
  news_publish_required: "Publisher name is required",
  news_latest_news: "News",
  news_btn_AddNews: "Add News",
  news_popOver_AddLang: "Add Language",
  news_popOver_Edit: "Edit",
  news_popOver_Delete: "Delete",
  article_donation: "Article Donation",
  news_delete: "Delete News",
  news_sure: "Are you sure you want to permanently delete this news?",
  news_not_found: "No News Record Found",
  news_not_click_add_news: "To add a new news article, click 'Add News'.",

  // All static content of Events
  same_time: "End time cannot be same as start time",
  events_AddEvent: "Add Event",
  events_latest_event: "Latest Events",
  events_title_required: "Title is required",
  events_desc_required: "Description is required",
  events_select_dropDown: "Select Event",
  events_select_globle: "Select Global Event",
  events_EditEvent: "Edit Event",
  events_startTime_required: "Start time is required",
  events_endTime_required: "End time is required",
  events_startDate_required: "Start date is required",
  events_endDate_required: "End date is required",
  events_delete: "Delete Event",
  events_sure: "Are you sure you want to permanently delete this event?",
  events_not_found: "No Event Record Found ",
  events_not_click_add_events: "To introduce a new event, click 'Add Event'",

  // All static content of Dharmshala
  dharmshala_floors_registered: "Floors",
  dharmshala_bookings_registered: "Registered Bookings",
  dharmshala_bookings_requested: "Requests",
  dharmshala_booking_delete: "Delete Booking",
  dharmshala_booking_delete_sure:
    "Are you sure you want to delete this Booking?",
  roomtypes_available: "Room Types Available",
  total_rooms_available: "Total Rooms Available",
  total_bookings: "Total Bookings",
  dharmshala_management: "Dharmshala",
  dharmshala_registered: "Registered Dharmshalas",
  building_registered: "Buildings",
  roomtype: "Room Type",
  capacity: "Capacity",
  price: "Price",
  dharmshala_roomtypes: "Room Types",
  dharmshala_roomtype_add: "Add Room Type",
  loading: "Loading",
  error_loading_room_types: "Error Loading Room Types",
  roomtype_name: "Name",
  dharmshala_roomtype_description: "Description",
  dharmshala_roomtype_capacity: "Capacity",
  dharmshala_roomtype_price: "Price",
  placeHolder_dharmshala_roomtype_name: "Enter Name",
  placeHolder_dharmshala_roomtype_description: "Enter Description",
  placeHolder_dharmshala_roomtype_capacity: "Enter Capacity",
  placeHolder_dharmshala_roomtype_price: "Enter Price",
  placeHolder_dharmshala_room_number: "Enter Room Number",
  dharmshala_roomtype_name_required: "Room type name is required",
  dharmshala_roomtype_description_required: "Room type description is required",
  dharmshala_roomtype_capacity_required: "Room type capacity is required",
  dharmshala_roomtype_price_required: "Room type price is required",
  dharmshala_roomtype_delete: "Delete Room Type",
  dharmshala_roomtype_delete_sure:
    "Are you sure you want to delete this room type?",
  roomtype_add: "Add Room Type",
  dharmshala_add: "Add Dharmshala",
  building_add: "Add Building",
  dharmshala_name: "Name",
  dharmshala_description: "Description",
  dharmshala_location: "Location",
  building_name: "Name",
  building_description: "Description",
  building_location: "Location",
  dharmshala_edit_dharmshala: "Edit Dharmshala",
  dharmshala_room_edit_dharmshala: "Edit Room",
  dharmshala_edit_roomtype: "Edit Roomtype",
  dharmshala_edit_building: "Edit Building",
  dharmshala_delete: "Delete Dharmshala",
  dharmshala_delete_sure: "Are you sure you want to delete this Dharmshala?",
  dharmshala_building_delete: "Delete Building",
  dharmshala_building_delete_sure:
    "Are you sure you want to delete this Building?",
  dharmshala_booking_add: "Book",
  dharmshala_booking_calendar: "Booking Calendar",
  dharmshala_floor_add: "Add Floor",
  dharmshala_floor_name: "Name",
  dharmshala_floor_description: "Description",
  dharmshala_floor_location: "Location",
  dharmshala_floor_edit_dharmshala: "Edit Floor",
  dharmshala_floor_delete: "Delete Floor",
  dharmshala_floor_delete_sure: "Are you sure you want to delete this Floor?",

  dharmshala_booking_id: "Booking ID",
  dharmshala_booking_start_date: "Booking Start Date",
  dharmshala_booking_end_date: "Booking End Date",
  dharmshala_booking_count: "Count",
  dharmshala_booking_status: "Status",
  dharmshala_booking_early_check_in: "Early Check In",
  dharmshala_booking_late_check_out: "Late Check Out",
  view_past_requests: "View Past Requests",
  view_upcoming_requests: "View Upcoming Requests",
  booking_room_delete: "Delete Room?",
  booking_room_delete_sure: "Are you sure you want to delete this Room?",
  booking_room_deleted: "Room Deleted",
  booking_room_deleted_message: "Room has been deleted successfully",
  requested: "Requested",
  accepted: "Accepted",
  reserved: "Reserved",
  confirmed: "Confirmed",
  checkedIn: "Checked In",
  completed: "Completed",
  cancelled: "Cancelled",
  maintenance: "Maintenance",
  cancel_booking: "Cancel Booking?",
  cancel_booking_sure: "Are you sure you want to cancel this Booking?",
  undo_cancel: "No, Go Back",
  confirm_cancel: "Yes, Cancel",

  dharmshala_rooms_registered: "Rooms",
  dharmshala_room_add: "Add Room",
  dharmshala_room_number: "Room Number",
  dharmshala_room_room_type: "Select Room Type",
  dharmshala_room_description: "Description",
  dharmshala_room_location: "Location",
  dharmshala_room_edit: "Edit Room",
  dharmshala_room_delete: "Delete Room",
  dharmshala_room_delete_sure: "Are you sure you want to delete this Room?",
  room_number: "Room Number",
  directBookingAvailable: "Direct Booking Available",
  room_type: "Room Type",

  // All static content of Pashudhan
  cattles_management: "Gaushala",
  cattle_expense_type_assets: "Assets",
  cattle_dead: "Dead",
  cattle_alive: "Alive",
  cattle_expense_type_consumable: "Consumable",
  cattle_expense_type_general: "General",
  cattle_info: "Pashudhan",
  cattle_photo: "Pashudhan's Photo",
  cattle_owner_photo: "Owner's Photo",
  cattle_owner_id: "Owner's ID",
  cattle_mother_id: "Mother Tag ID",
  cattle_breed: "Breed",
  cattle_date_of_birth: "Date of Birth",
  cattle_age: "Age",
  cattle_is_pregnant: "Is Pregnant",
  cattle_is_milking: "Is Milking",
  cattle_milk_quantity: "Milk Quantity",
  cattle_fees: "Medical Expense",
  cattle_medical_record: "Medical Records",
  cattle_pregnancy_record: "Pregnancy Records",
  cattle_expense: "Expense Management",
  cattle_stock_management: "Stock Management",
  cattle_usage: "Usage Management",
  cattle_revenueHeading: "Daily Registration Report ",
  recent_registered_cattles: "Recent Registered Pashudhan",
  cattle_registered: "Registered Pashudhan",
  cattle_add: "Add Pashudhan",
  cattle_record_add: "Add Record",
  cattle_breed_add: "Add Breed",
  cattle_breed_edit: "Edit Breed",
  cattle_medical_add: "Add Medical Record",
  cattle_pregnancy_report: "Pregnancy Records",
  cattle_pregnancy_report_add: "Add Pregnancy Record",
  cattle_expense_add: "Add Expenses",
  cattle_supplies_add: "Add Supplies",
  cattle_items_add: "Add Items",
  cattle_id: "Pashudhan ID",
  calf_id: "Calf ID",
  cattle_treatment_medicine: "Treatment/Medicine",
  cattle_dosage: "Dosage",
  cattle_dr_name: "Dr. Name",
  cattle_purchase_price: "Purchase Price",
  cattle_symptoms: "Symptoms",
  cattle_usage_add: "Add Usage",
  cattles: "Pashudhan",
  cattles_feed: "Feeds",
  cattles_medical: "Medical",
  cattles_death: "Deaths",
  cattles_machinery: "Machinery",
  cattle_conceiving_date: "Conceiving Date",
  cattle_pregnancy_date: "Pregnancy Date",
  cattle_delivery_date: "Delivery Date",
  cattle_pregnancy_status: "Pregnancy Status",
  cattle_purpose: "Purpose",
  cattle_itemId: "Item ID",
  cattle_itemName: "Item Name",
  cattle_expense_quantity: "Quantity",
  cattle_expense_bill_invoice: "Bill/Invoice",
  cattle_expense_bill_invoice_upload: "Upload ",
  cattle_unit: "Unit",
  cattle_expense_unitType: "Unit Type",
  cattle_expense_current_quantity: "Current Quantity",
  cattle_expense_order_quantity: "Order Quantity",
  cattle_last_update: "Last Updated",
  cattle_tag_id: "Tag ID",
  cattle_date_purchased: "Date Purchased",
  cattle_source: "Source",
  cattle_owner_name: "Owner's/Gaurakshak Name",
  cattle_owner_number: "Owner's/Gaurakshak Number",
  cattle_owner_image: "Owner's/Gaurakshak Image",
  cattle_cow_image: "Cow Image",
  cattle_is_dead: "Is Dead",
  cattle_date_death: "Date of Death",
  cattle_death_reason: "Reason of Death",
  cattle_is_sold: "Is Sold",
  cattle_purchaser_name: "Purchaser Name",
  cattle_purchaser_number: "Purchaser Number",
  cattle_purchaser_id: "Purchaser ID",
  cattle_sold_date: "Sold Date",
  cattle_sold_price: "Sold Price",
  cattle_edit_medical: "Edit Medical Record",
  cattle_edit_pregnancy: "Edit Pregnancy Record",
  cattle_edit_cattle: "Edit Pashudhan",
  cattle_edit_item: "Edit Item",
  cattle_edit_supplies: "Edit Supplies",
  cattle_edit_usage: "Edit Usage",
  cattle_category_edit: "Edit Category",
  cattle_category_add: "Add Category",
  cattle_stock: "Stock",
  cattle_supplies: "Supplies",
  cattle_usage: "Usage",
  cattle_items: "Items",

  //dharmshala validation messages
  dharmshala_name_required: "Dharmshala name is required",
  dharmshala_description_required: "Dharmshala description is required",
  dharmshala_location_required: "Dharmshala location is required",
  building_name_required: "Building name is required",
  building_description_required: "Building description is required",
  building_location_required: "Building location is required",
  dharmshala_room_number_required: "Room Number is required",
  dharmshala_floor_name_required: "Dharmshala floor name is required",
  dharmshala_floor_description_required:
    "Dharmshala floor description is required",
  dharmshala_floor_number: "Floor Number",
  dharmshala_floor_number_required: "Dharmshala floor number is required",
  // cattle validation messages
  cattle_itemID_required: "Item ID is required",
  cattle_id_required: "Pashudhan ID is required",
  cattle_pregnancy_status_required: "Pregnancy Status is required",
  cattle_name_required: "Name is required",
  cattle_order_quantity_required: "Order Quantity is required",
  cattle_unit_required: "Unit is required",
  cattle_unit_type_required: "Unit Type is required",
  cattle_quantity_required: "Quantity is required",
  cattle_purpose_required: "Purpose is required",
  cattle_treatment_medicine_required: "Treatment/Medicine is required",
  cattle_dosage_required: "Dosage is required",
  cattle_DrName_required: "Dr. Name is required",
  cattle_price_required: "Medical Expense is required",
  cattle_symptoms_required: "Symptoms is required",
  cattle_tag_id_required: "Tag ID is required",
  cattle_type_required: "Category is required",
  cattle_breed_required: "Breed is required",
  cattle_age_required: "Age is required",
  cattle_purchase_price_required: "Purchase is required",
  cattle_source_required: "Source is required",
  cattle_owner_name_required: "Owner's name is required",
  cattle_owner_id_required: "Owner's id is required",
  cattle_deathReason_required: "Death Reason is required",
  cattle_purchaser_name_required: "Purchaser Name is required",
  cattle_purchaser_id_required: "Purchaser ID is required",
  cattle_sold_price_required: "Sold Price is required",
  cattle_milk_quantity_required: "Milk Quantity is required",

  // all cattle management delete messages
  cattle_cattle_delete: "Delete Pashudhan",
  cattle_cattle_sure: "Are you sure you want to delete this pashudhan?",
  cattle_medical_delete: "Delete Medical Info",
  cattle_medical_sure: "Are you sure you want to delete this medical report?",
  cattle_pregnancy_delete: "Delete Pregnancy Report",
  cattle_pregnancy_sure:
    "Are you sure you want to delete this pregnancy report?",
  cattle_supplies_delete: "Delete Supply",
  cattle_supplies_sure: "Are you sure you want to delete this supply?",

  cattle_usage_delete: "Delete Usage",
  cattle_usage_sure: "Are you sure you want to delete this usage?",

  cattle_item_delete: "Delete Item",
  cattle_item_sure: "Are you sure you want to delete this item?",
  cattle_breed_delete: "Delete Breed",
  cattle_breed_sure: "Are you sure you want to delete this breed?",

  // All cattle search variable
  search_cattles: "Search by PashudhanID, Type, Breed ",
  search_cattles_medical: "Search Pashudhan Medical Info",
  search_cattles_pregnancy: "Search Pashudhan Pregnancy Report",
  search_cattles_stock: "Search Stock",
  search_cattles_supplies: "Search Item Supplies",
  search_cattles_usage: "Search Item Usages",
  search_cattles_items: "Search Pashudhan Item",
  // All static content of Notices

  notices_AddNotice: "Add Notice",
  notices_latest_Notice: "Notices",
  add_image: "Add Image",
  image_size_suggestion:
    "(Please upload the image with the size of 450px*350px)",
  notices_title_required: "Title is required",
  notices_desc_required: "Description is required",
  notices_select_dropDown: "Select Notice",
  notices_EditNotice: "Edit Notice",

  notices_delete: "Delete Notice",
  notices_sure: "Are you sure you want to permanently delete this notice ? ",
  notices_not_found: "No Notices Found",
  notices_not_click_add_notices:
    "To include a new notice, simply click on 'Add Notice'.",

  // All static content of Categories
  only_letters: "Sub category can only contain character letters",
  categories_AddCategory: "Add Sub-Category",
  categories_serial_number: "Serial Number",
  categories_master_category: "Master Category",
  categories_sub_category: "Sub Category",
  categories_category_required: "Master category is required",
  categories_sub_category_required: "Sub Category is required",
  categories_latest_Category: "Categories",
  categories_title_required: "Category Name is required",
  categories_EditCategory: "Edit Category",
  categories_select_category: "Category",
  categories_select_amount: "Amount",
  category_select_sub_category: "Sub Category",
  categories_select_user_name: "Username",
  category_select: "Select",
  categories_select_master_category: "Select Master Category",
  donar_name_required: "Donor name is Required",
  created_by_required: "Created by is Required",
  user_select_required: "Username is required",
  masterCategory_required: "Category is required",
  subCategory_required: "Please Select a Sub Category",
  commitmentID_required: "Please Select Commiment Id ",
  amount_required: "Amount is required",
  mode_of_payment: "Mode of Payment",
  bank_name: "Bank Name",
  cheque_no: "Card/DD/Cheque No",
  cheque_date: "DD/Cheque Date",
  cheque_status: "DD/Cheque Clearance Status",
  bank_narration: "Bank Narration",
  remark: "Remark",
  articleType: "Article Type",
  articleUnit: "Article Unit",
  articleWeight: "Article Weight",
  articleQuantity: "Article Quantity",
  articleItem: "Article Item",
  articleRemark: "Article Remark",
  mode_of_payment: "Mode of Payment",
  bank_name: "Bank Name",
  cheque_no: "Card/DD/Cheque No",
  cheque_date: "DD/Cheque Date",
  cheque_status: "DD/Cheque Clearance Status",
  bank_narration: "Bank Narration",
  remark: "Remark",

  category_delete: "Delete Category",
  category_sure: "Are you sure you want to permanently delete this category ? ",
  category_not_found: "Categories Unavailable  ",
  category_not_click_add_category:
    "To introduce a new category, click on 'Add Category'.",
  masters_not_found: "Master List Unavailable",

  // All static content of Users
  users_AddUser: "Add Sub Admin ",
  users_latest_User: "Sub Admin ",
  users_title_required: "Username is required",
  user_only_letters: "Username only contain alphabets ",
  users_mobile_required: "Mobile Number is Required",
  mobile_number_limit: "Mobile Number must be 10 digits",
  users_email_required: "Email is Required",
  users_not_found: "Sub Admins Not Found  ",
  users_not_click_add_users:
    "To add a new sub admin user, click 'Add Sub Admin'.",

  // All static content of Internal Expense
  expenses_AddExpenses: "Add Expense",
  price_per_item: "Price per item",
  expense_type_required: "Expense Type is required",
  expenses_EditExpenses: "Edit Expense",
  expenses_Date: "Date",
  expence_description: " Description",
  expenses_latest_Expenses: "All Expenses",
  expenses_title_required: "Title is required",
  expenses_mobile_required: "Mobile Number is Required",
  expenses_email_required: "Email is Required",
  added_by: "Added By",
  expenses_desc_required: "Description is Required",
  expence_amount_required: "Amount is Required",

  expence_delete: "Delete Expense",
  expence_sure: "Are you sure you want to permanently delete this expense ? ",
  expence_not_found: "No Expenses Found ",
  expence_not_click_add_expence:
    "To record new expenses, click on 'Add Expenses'.",

  // All static content of Financial Report

  report_AddReport: "Financial Reports",
  report_expences: "Expenses",
  remarks_financial_donationBox: "Remarks",
  report_commitment: "Pledge",
  reports_not_found: "Report & Disput Not Found",
  report_donation_box: "Donation Box",
  finance_report_no_found:
    "No Financial Report Available for Selected Timeline",
  financial_not_found: "No Financial Found",

  // All static content of Donation
  donation_Donation: "Donation",
  start_Time: "Start Time",
  end_Time: "End Time",
  is_government: "Is Government Donor",
  donation_Adddonation: "Add Donation",
  donation_AddArticledonation: "Add Article Donation",
  donation_suspense: "Add Suspense Record",
  add_memberShip_member: "Add Member",
  edit_memberShip_member: "Edit Member Profile",
  suspense_delete: "Delete Suspense Record",
  suspense_sure: "Are you sure you want to permanently delete this Record ? ",
  original_amount: "Original Amount",
  estimate_amount: "Estimate Amount",
  donation_select_date_time: "Select Date",
  donation_select_date: "Select Date",
  donation_donar_name_only_letters:
    "Donor Name can only contain character letters",
  add_user_donation: "No user found click here to",
  donation_delete: "Delete Donation",
  donation_sure: "Are you sure you want to permanently delete this donation ? ",
  donation_not_found: "No Donation Found",
  add_user: "Add User",
  donation_paid_not_found: "No Paid Donations Found",
  donation_not_click_add_donation:
    'Click on "Add Donation" to add donation on the screen',

  // All static content of Donation Box
  DonationBox_total_collection: "Total Collection:",
  DonationBox_DonationBox: "Donation Box",
  DonationBox_AddCollectionBox: "Add Collection ",
  DonationBox_EditCollectionBox: "Edit Collection ",
  created_by: "Created By",
  mode_of_payment: "Mode of payment",
  bank_name: "Bank",
  cheque_no: "Card/DD/Cheque No",
  cheque_date: "Cheque Date",
  cheque_status: "DD/Cheque Clearance Status",
  bank_narration: "Bank Narration",
  donation_remark: "Donation Remark",
  edit_collection: "Edit Collection",
  enter_price_manually: "Enter Amount",
  enter_cheque_no: "Enter Cheque no",
  enter_bank_narration: "Enter Bank Narration",
  viewLogs: "View Logs",
  logs: "Logs",
  add_remarks: "Add Remarks",
  add_amount: "Add Amount",
  DonationBox_AddDonationBox: "Add Donation Box ",
  donation_box_desc_required: "Description is Required",
  donation_box_amount_required: "Amount is Required",

  logData_editedBy: "Edited By",
  logData_createdBy: "Created By",
  logData_timeDate: "Time & Date",
  logData_createdAmount: "Created Amount",
  logData_editedAmount: "Edited Amount",

  donation_box_not_found: "No Donation Box Collection Record Found",
  donation_box_not_click_add_donation_box:
    "To introduce a new donation box, just click 'Add Collection'",
  //All Suspense Data
  suspense: "Suspense",
  map_fields: "Map Fields",
  sample_file_download: "Sample File Download",
  transactionDate: "Transaction Date & Time",
  suspense_cheque_no: "Cheque No",
  suspense_transId: "Transaction ID",
  bankNarration: "Bank Narration",
  suspense_amount: "Amount",
  suspense_mode_of_payment: "Mode of Payment",
  edit_suspense_record: "Edit Suspense Record",
  add_suspense_record: "Add Suspense Record",
  suspense_id: "Id",
  start_date_time: "Start Date Time",
  end_date_time: "End Date Time",
  suspense_status: "Status",
  suspense_success_count: "Success Count",
  suspense_failed_count: "Failed Count",
  suspense_import_result: "Import Result",
  copyToClipboard: "Copy to Clipboard",
  req_transactionDate: "Transaction date is required",
  req_bankNarration: "Bank narration is required",
  req_ammount: "Amount is required",
  req_modeofPayment: "Mode of Payment is required",
  target_Fields: "Target Fields",
  source_Fields: "Source Fields",

  // All Pledge Data
  notify_user: "Notify User",
  notify_user_content: "Please check the check box for nudge the user",
  commitment_Username: "Username",
  add_commitment: "Add Pledge",
  edit_commitment: "Edit Pledge",
  commitment_end_Date: "End Date ",
  commitment_Amount_Due: "Amount Due",
  commitment_select_commitment_id: "Select Pledge ID",
  commitment_select_start_date: "Select Start Date",
  commitment_select_end_date: "Select End Date",

  commitment_delete: "Delete Pledge",
  commitment_sure: "Are you sure you want to permanently delete this pledge ? ",
  commitment_not_found: "Pledge Not Found  ",
  commitment_not_click_add_commitment:
    "To make a new pledge, click 'Add Pledge'.",
  commitment_complete: "Completed",
  commitment_partial: "Partial",

  // All subscribed user static data
  subscribed_user_add_user: "Add Subscribed User",
  subscribed_user_name: "Username",
  subscribed_user_pan_cardDetail: "PAN card Details",
  subscribed_user_date_birth: "Date of Birth ",
  subscribed_user_email: "Email",
  subscribed_user_address: "Address",
  user_name: "Username",
  subscribed_not_found: "No Subscribed User Found",
  subscribed_not_click_add:
    'Click on "Add Subscribed User" to add subscribed user on the screen',

  // All report & dispute static data
  report_report_against: "Name",
  report_mobile: "Mobile Number",
  export_report: "Export Report",
  report_Transaction_IDs: "Transaction IDs",
  report_panding: "Pending",
  report_re_solved: "Re-Solved",
  report_dispute_not_found: "Reports & Disputes Not Found  ",

  // All notification static data
  notifications: "Notifications",
  notifications_Notify_Users: "Notify Users",
  notifications_not_found: "No Notification Found",
  // notifications_not_click_add:'Click on "Add Notify Users" to add notifications on the screen',

  // All user Profile static Data
  about_trust: "ABOUT US",
  trust_address: "Address",
  trust_user: "ABOUT USER",
  trust_location: "LOCATION",
  trust_facility: "FACILITIES",
  trust_facebookLink: "Facebook Link",
  trust_facebookLink_required: "Facebook link is required",
  trust_contact_number_required: "Contact number is required.",
  contact_number: "Contact Number",
  trust_image_certificate: "IMAGES AND CERTIFICATES",
  location: "Location",
  trust_prefenses: "Trust Preferences",
  trust_prefenses_required: "Trust Preferences is required",
  latitude: "Latitude",
  longitude: "Longitude",
  userProfile: "Profile",
  user_editUser: "Edit User",
  user_userRoleRequired: "User Role is required",
  minimum_one_role_required: "A user role must be selected",
  password_required: "Password is required",
  userProfile_phone_number: "Phone Number",
  trust_trustType: "Trust Type",
  about_trusts: "About",
  userProfile_name: "Name",
  trust_type_required: "Trust type is required",
  trust_about_required: "Trust about is required",
  State: "State",
  City: "City",
  events_state_required: "State is required",
  events_city_required: "City is required",
  events_location_required: "Location is required",
  facilities: "Trust Facilities",
  userProfile_email_id: "Email",
  userProfile_temple: "Temple",
  latitude: "Longitude",
  longitude: "Latitude",
  longitude_required: "Longitude is required",
  latitude_required: "Latitude is required",
  user_password: "Password",
  user_password_old: "Old Password",
  user_password_new: "New Password",
  edit_image: "Edit",
  user_password_confirm: "Confirm Password",
  userProfile_trust_type: "Trust Type",
  userProfile_doc_certificate: "Document/Certificate",
  userProfile_facilities: "Facilities",
  userProfile_punyarjak: "Punyarjak",
  userProfile_temple_upload_doc: "Upload your temple document",
  user_userRole: "User Roles",
  images: "Images",
  browse: "Browse",
  update_profile: "Update",
  name_required: "Name is required",
  start_time: "Start Time",
  start_time_required: "Start Time is required",
  end_time: "End Time",
  end_time_required: "End Time is required",

  email_invalid: "Invalid Email",
  email_required: "Email is required",
  number_type: "That doesn't look like a phone number",
  cant_start_minus: "A phone number can't start with a minus",
  number_in_point: "A phone number can't include a decimal point",
  number_required: "Phone number is required",
  address_required: "Address required",
  Temple_name_required: "Temple name is required",
  doc_required: "Temple document required",

  // punyarjak

  punyarjak: "Punyarjak",
  add_punyarjak: "Add Punyarjak",
  img_required: "Image is required",
  edit_punyarjak: "Edit Punyarjak",
  name: "Name",
  description: "Description",
  punyarjak_desc_required: "Description is required",
  punyarjak_delete: "Delete Punyarjak",
  username: "The username must only consist of letters of the alphabet",
  punyarjak_sure:
    "Are you sure you want to permanently delete this punyarjak ? ",
  punyarjak_not_found: "No Punyarjak Record Found ",
  punyarjak_not_click_add:
    "To include a new Punyarjak user, click 'Add Punyarjak'.",

  //membership family member fields
  member_add_info: "Address Information",
  member_home_add: "Home Address",
  member_same_add: "Same as home address",
  member_family_name: "Name",
  member_dob: "Date of Birth",
  member_anniversary: "Date of Anniversary",
  member_family_relation: "Relation",
  member_cor_add: "Correspondence Address",
  member_family_form: "Add Family Member",
  edit_family_member: "Edit Family Member",
  save: "Save",
  required_family_name: "Please Enter Name",
  required_family_relation: "Please Select Relation",
  upload_profile_image: "Upload Profile Image",
  // all place holder images
  placeHolder_family_name: "Enter Name",
  placeHolder_family_relation: "Select Relation",
  placeHolder_donar_name: "Enter Donor Name",
  placeHolder_price_per_item: "Enter price per item",
  placeHolder_mobile_number: "Enter Mobile Number",
  placeHolder_phone_number: "Enter Contact Number",
  placeHolder_title: "Enter Title",
  placeHolder_description: "Enter Description",
  placeHolder_tags: "Enter Tags",
  placeHolder_name: "Enter Name",
  placeHolder_user_name: "Enter Username",
  placeHolder_address: "Enter Address",
  placeHolder_email: "Enter Email",
  placeHolder_password: "Enter Password",
  placeHolder_sub_category: "Enter Sub Category",
  placeHolder_country: "Enter Country",
  placeHolder_pan: "Enter PAN",
  placeHolder_city: "Enter City",
  placeHolder_state: "Enter State",
  placeHolder_location: "Enter Location",
  placeHolder_trust_facebook: "Enter Facebook Link",
  placeHolder_trust_address: "Enter Address ",
  placeHolder_cattle_id: "Select Pashudhan Id",
  placeHolder_calf_id: "Select Calf Id",
  placeHolder_cattle_treatment_medicine: "Enter Treatment/Medicine here",
  placeHolder_cattle_dosage: "Enter dosage here",
  placeHolder_cattle_dr_name: "Enter Dr. Name",
  placeHolder_cattle_purchase_price: "Enter Price here",
  placeHolder_cattle_fees: "Enter Medical Expense",
  placeHolder_cattle_symptoms: "Enter Symptoms",
  placeHolder_cattle_pregnancy_status: "Enter Pregnancy Status",
  placeHolder_cattle_itemId: "Select Item ID",
  placeHolder_cattle_item_name: "Select Item Name",
  placeHolder_cattle_expense_quantity: "Enter Quantity here",
  placeHolder_cattle_unit: "Select Quantity Unit",
  placeHolder_cattle_expense_unitType: "Select Unit Type",
  placeHolder_cattle_purpose: "Enter Purpose",
  placeHolder_cattle_item_id: "Enter Item Name here",
  placeHolder_cattle_expense_current_quantity: "Enter Current Quantity",
  placeHolder_cattle_expense_order_quantity: "Enter Order Quantity",
  placeholder_cattle_type: "Select Pashudhan Category",
  placeHolder_cattle_breed: "Select Pashudhan Breed",
  placeHolder_cattle_age: "Enter Age Here",
  placeHolder_cattle_source: "Select Source",
  placeHolder_cattle_owner_name: "Enter Owner's/Gaurakshak Name",
  placeHolder_cattle_owner_Id: "Enter Owner's/Gaurakshak ID",
  placeHolder_cattle_tag_id: "Enter Tag ID",
  placeholder_cattle_mother_id: "Select or Enter Mother Tag ID",
  placeHolder_cattle_owner_number: "Enter Owner's/Gaurakshak Name",
  placeHolder_cattle_death_reason: "Enter Reason of Death here",
  placeHolder_cattle_purchaser_name: "Enter Purchaser Name",
  placeHolder_cattle_purchaser_number: "Enter Purchaser Number",
  placeHolder_cattle_purchaser_id: "Enter Purchaser ID",
  placeHolder_cattle_sold_price: "Enter Sold Price",
  placeHolder_cattle_milk_quantity: "Enter Milk Quantity in liters",
  placeHolder_expense_type: "Select Expense Type",
  placeHolder_dharmshala_name: "Enter Dharmshala Name",
  placeHolder_dharmshala_description: "Enter Dharmshala Description",
  placeHolder_dharmshala_location: "Enter Dharmshala Location",

  placeHolder_building_name: "Enter Building Name",
  placeHolder_building_description: "Enter Building Description",
  placeHolder_building_location: "Enter Building Location",

  placeHolder_dharmshala_floor_name: "Enter Floor Name",
  placeHolder_dharmshala_floor_description: "Enter Floor Description",
  placeHolder_dharmshala_floor_number: "Enter Floor Number",

  placeHolder_booking_id: "Enter Booking ID",
  placeHolder_booking_start_date: "Enter Booking Start Date",
  placeHolder_booking_end_date: "Enter Booking End Date",
  placeHolder_booking_count: "Enter Count",
  placeHolder_booking_status: "Enter Booking Status",
  placeHolder_early_check_in: "Early Check In Required?",
  placeHolder_late_check_out: "Late Check Out Required?",

  // All search static keys
  search_notification: "Search All Notifications",
  search_subscribe_user: "Search All Subscribe User",
  search_news: "Search All News",
  search_events: "Search All Events",
  search_notices: "Search All Notices",
  search_categories: "Search All Categories",
  search_users: "Search All Users",
  search_disputes: "Search All Disputes",
  search_expenses: "Search All Expenses",
  search_financial_reports: "Search All Financial Reports",
  search_donation: "Search All Donations",
  search_dharmshala: "Search All Dharmshalas",
  search_bookings: "Search By Booking Id",
  search_roomtypes: "Search All Room Types",
  search_buildings: "Search All Buildings",
  search_feedback: "Search All Feedback",
  search_punyarjak: "Search All Punyarjak",
  search_donation_box_collection: "Search All Donation Box",
  search_commitment: "Search All Pledges",

  Overview: "Overview",
  Bookings: "Bookings",
  Buildings: "Buildings",
  RoomTypes: "Room Types",
  Feedback: "Feedback",
  enter_remark_here: "Enter remarks here",
  Remarks: "Remarks",
  Logout: "Logout",
  total_registered_pashudhan: "Total Registered Pashudhan",
  total_gaushala_donation: "Total Gaushala Donation",
  dead_cattles: "Dead Pashudhan",
};
