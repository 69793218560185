export const hindiContent = {
  no_data_found: "कोई डेटा नहीं मिला",
  no_data_found_add_data:
    'स्क्रीन पर डेटा जोड़ने के लिए "जोड़ें बटन" पर क्लिक करें',
  amount: "राशि",
  all: "सभी",
  dashboard: "डैशबोर्ड",
  donation: "दान",
  article_donation: "वस्तु दान",
  suspense: "अज्ञात",
  commitment: "बोली",
  hundi: "दान पेटी संग्रह",
  financial_reports: "वित्तीय रिपोर्ट",
  internal_expenses: "आंतरिक खर्चे",
  total_donation: "कुल दान:",
  events: "कार्यक्रम",
  total: "कुल:",
  Select: "चुनें",
  news: "समाचार",
  notices: "सूचनाएं",
  configuration: "कॉन्फ़िगरेशन",
  searchBarPlaceHolder: "मंदिर, ट्रस्ट, कार्यक्रम, समाचार खोजें",
  monthName_January: "जनवरी",
  monthName_February: "फरवरी",
  monthName_March: "मार्च",
  monthName_April: "अप्रैल",
  monthName_May: "मई",
  monthName_June: "जून",
  monthName_July: "जुलाई",
  monthName_August: "अगस्त",
  monthName_September: "सितंबर",
  monthName_October: "अक्टूबर",
  monthName_November: "नवंबर",
  monthName_December: "दिसंबर",
  last_login: "अंतिम लॉगिन",
  category: "श्रेणी",
  user: "उप-व्यवस्थापक",
  report_Dispute: "रिपोर्ट और विवाद",
  cancel: "रद्द करें",
  confirm: "हटाने की पुष्टि करें",
  save_changes: "परिवर्तन सहेजें",
  rupees: "रुपये",
  downloadReceipt: "रसीद डाउनलोड करें",
  pay_donation: "दान भुगतान करें",
  payment: "अब भुगतान करें",
  paymentPaid: "भुगतान किया गया",
  injection_found: "विशेष वर्ण समर्थित नहीं हैं",
  logout_msg: "क्या आप वाकई लॉगआउट करना चाहते हैं?",
  no: "नहीं",
  yes: "हाँ",
  tags_limit: "टैग सीमा समाप्त हो गई है",
  end_time_less: "समाप्ति समय प्रारंभ समय से अधिक होना चाहिए",
  publish_now: "अभी प्रकाशित करें",
  already_publish: "समाचार पहले से ही प्रकाशित है।",
  already_publish_notice: "सूचना पहले से ही प्रकाशित है।",
  unPublish: "अप्रकाशित करें",
  schedule: "अनुसूची",
  reSchedule: "पुनः अनुसूची",
  publish: "प्रकाशित करें",
  published: "प्रकाशित",
  invalid_amount: "अमान्य मूल्य",
  tagsChar_limit:
    "टैग फ़ील्ड में 20 वर्णों की सीमा होती है और इससे अधिक स्वीकार नहीं कर सकते हैं।",

  // Dashboard के सभी स्थिर सामग्री
  dashboard_donationReceived: "प्राप्त दान",
  dashboard_donationPending: "लंबित दान",
  dashboard_all: "सभी",
  donation_hundi: "दान + हंडी",
  dashboard_totalExpenses: "कुल खर्चे",
  noTopDonar: "कोई नहीं है",

  dashboard_RevenueReport: "वित्तीय रिपोर्ट",
  notification_not_found: "कोई सूचना नहीं मिली",
  dashboard_monthly: "इस महीने",
  dashboard_weekly: "इस सप्ताह",
  dashboard_yearly: "इस साल",
  dashboard_daily: "दैनिक",

  dashboard_card_title3: "सदस्यता प्राप्त उपयोगकर्ता",
  dashboard_Recent_DonationCommitment: "हाल के दान",
  dashboard_Recent_DonorName: "दाता का नाम",
  dashboard_Recent_DonorNumber: "मोबाइल नंबर",
  dashboard_Recent_DonorType: "प्रकार",
  dashboard_Recent_DonorDate: "तिथि और समय",
  dashboard_Recent_DonorStatus: "स्थिति",
  dashboard_Recent_DonorAmount: "राशि",
  dashboard_Recent_DonorCommitId: "बोली आईडी",
  dashboard_Recent_DonorReceipt: "रसीद",
  dashboard_viewAll: "सभी देखें",
  dashboard_top: "शीर्ष 5 दाता",

  // समाचार की सभी स्थिर सामग्री

  news_AddNews: "समाचार जोड़ें",
  news_EditNews: "समाचार संपादित करें",
  news_AddLangNews: "भाषा जोड़ें",
  news_InputIn: "भाषा चुनें:",
  news_label_Title: "शीर्षक",
  news_label_Tags: "टैग",
  news_label_Description: "विवरण जोड़ें",
  news_label_Date: "चयनित तिथि",
  news_label_Published: "द्वारा प्रकाशित",
  news_label_ImageVedio: "चित्र जोड़ें",
  news_button_Publish: "प्रकाशित करें",
  news_title_required: "शीर्षक आवश्यक है",
  news_tags_required: "टैग आवश्यक हैं",
  news_desc_required: "विवरण आवश्यक है",
  news_publish_required: "प्रकाशक का नाम आवश्यक है",
  news_latest_news: "समाचार",
  news_btn_AddNews: "समाचार जोड़ें",
  news_popOver_AddLang: "भाषा जोड़ें",
  news_popOver_Edit: "संपादित करें",
  news_popOver_Delete: "हटाएं",

  news_delete: "समाचार हटाएं",
  news_sure: "क्या आप वाकई इस समाचार को स्थायी रूप से हटाना चाहते हैं?",
  news_not_found: "समाचार अनुपस्थित",
  news_not_click_add_news:
    "नया समाचार जोड़ने के लिए, 'समाचार जोड़ें' पर क्लिक करें।",

  // कार्यक्रम की सभी स्थिर सामग्री
  same_time: "समाप्ति समय प्रारंभ समय के समान नहीं हो सकता",
  events_AddEvent: "कार्यक्रम जोड़ें",
  events_latest_event: "नवीनतम कार्यक्रम",
  events_title_required: "शीर्षक आवश्यक है",
  events_desc_required: "विवरण आवश्यक है",
  events_select_dropDown: "कार्यक्रम चुनें",
  events_select_globle: "वैश्विक कार्यक्रम चुनें",
  events_EditEvent: "कार्यक्रम संपादित करें",
  events_startTime_required: "प्रारंभ समय आवश्यक है",
  events_endTime_required: "समाप्ति समय आवश्यक है",
  events_startDate_required: "प्रारंभ तिथि आवश्यक है",
  events_endDate_required: "समाप्ति तिथि आवश्यक है",
  events_delete: "कार्यक्रम हटाएं",
  events_sure: "क्या आप वाकई इस कार्यक्रम को स्थायी रूप से हटाना चाहते हैं?",
  events_not_found: "कार्यक्रम अनुपस्थित",
  events_not_click_add_events:
    "नया कार्यक्रम जोड़ने के लिए, 'कार्यक्रम जोड़ें' पर क्लिक करें।",

  // पशुधन की सभी स्थिर सामग्री
  cattles_management: "गौशाला",
  cattle_expense_type_assets: "संपत्ति",
  cattle_dead: "मृत",
  cattle_alive: "जीवित",
  cattle_expense_type_consumable: "उपयोगी",
  cattle_expense_type_general: "सामान्य",
  cattle_info: "पशुधन जानकारी",
  cattle_photo: "पशुधन का फोटो",
  cattle_owner_photo: "मालिक का फोटो",
  cattle_owner_id: "मालिक का आईडी",
  cattle_mother_id: "मां टैग आईडी",
  cattle_breed: "नस्ल",
  cattle_date_of_birth: "जन्म तिथि",
  cattle_age: "उम्र",
  cattle_is_pregnant: "गर्भवती है",
  cattle_is_milking: "दूध दे रही है",
  cattle_milk_quantity: "दूध की मात्रा",
  cattle_fees: "चिकित्सा खर्च",
  cattle_medical_record: "चिकित्सा रिकॉर्ड",
  cattle_pregnancy_record: "गर्भावस्था रिकॉर्ड",
  cattle_expense: "व्यय प्रबंधन",
  cattle_stock: "स्टॉक प्रबंधन",
  cattle_usage: "उपयोग प्रबंधन",
  cattle_revenueHeading: "दैनिक पंजीकरण रिपोर्ट",
  recent_registered_cattles: "हाल में पंजीकृत पशुधन",
  cattle_registered: "पंजीकृत पशुधन",
  cattle_add: "पशुधन जोड़ें",
  cattle_record_add: "रिकॉर्ड जोड़ें",
  cattle_breed_add: "नस्ल जोड़ें",
  cattle_breed_edit: "नस्ल संपादित करें",
  cattle_medical_add: "चिकित्सा रिकॉर्ड जोड़ें",
  cattle_pregnancy_report: "गर्भावस्था रिकॉर्ड",
  cattle_pregnancy_report_add: "गर्भावस्था रिकॉर्ड जोड़ें",
  cattle_expense_add: "खर्च जोड़ें",
  cattle_supplies_add: "आपूर्ति जोड़ें",
  cattle_items_add: "आइटम जोड़ें",
  cattle_id: "पशुधन आईडी",
  calf_id: "बछड़ा आईडी",
  cattle_treatment_medicine: "उपचार/दवा",
  cattle_dosage: "मात्रा",
  cattle_dr_name: "डॉ. का नाम",
  cattle_purchase_price: "खरीद मूल्य",
  cattle_symptoms: "लक्षण",
  cattle_usage_add: "उपयोग जोड़ें",
  cattles: "पशुधन",
  cattles_feed: "चारा",
  cattles_medical: "चिकित्सा",
  cattles_death: "मृत्यु",
  cattles_machinery: "मशीनरी",
  cattle_conceiving_date: "गर्भाधान की तारीख",
  cattle_pregnancy_date: "गर्भावस्था की तारीख",
  cattle_delivery_date: "प्रसव की तारीख",
  cattle_pregnancy_status: "गर्भावस्था की स्थिति",
  cattle_purpose: "उद्देश्य",
  cattle_itemId: "आइटम आईडी",
  cattle_itemName: "आइटम का नाम",
  cattle_expense_quantity: "मात्रा",
  cattle_expense_bill_invoice: "बिल/चालान",
  cattle_expense_bill_invoice_upload: "अपलोड करें",
  cattle_unit: "इकाई",
  cattle_expense_unitType: "इकाई प्रकार",
  cattle_expense_current_quantity: "वर्तमान मात्रा",
  cattle_expense_order_quantity: "आर्डर की मात्रा",
  cattle_last_update: "अंतिम अपडेट",
  cattle_tag_id: "टैग आईडी",
  cattle_date_purchased: "खरीद की तारीख",
  cattle_source: "स्रोत",
  cattle_owner_name: "मालिक का नाम",
  cattle_owner_number: "मालिक का नंबर",
  cattle_owner_image: "मालिक का फोटो",
  cattle_cow_image: "गाय की तस्वीर",
  cattle_is_dead: "मृत है",
  cattle_date_death: "मृत्यु की तारीख",
  cattle_death_reason: "मृत्यु का कारण",
  cattle_is_sold: "बेचा गया है",
  cattle_purchaser_name: "खरीदार का नाम",
  cattle_purchaser_number: "खरीदार का नंबर",
  cattle_purchaser_id: "खरीदार का आईडी",
  cattle_sold_date: "बिक्री की तारीख",
  cattle_sold_price: "बिक्री मूल्य",
  cattle_edit_medical: "चिकित्सा रिकॉर्ड संपादित करें",
  cattle_edit_pregnancy: "गर्भावस्था रिकॉर्ड संपादित करें",
  cattle_edit_cattle: "पशुधन संपादित करें",
  cattle_edit_item: "आइटम संपादित करें",
  cattle_edit_supplies: "आपूर्ति संपादित करें",
  cattle_edit_usage: "उपयोग संपादित करें",
  cattle_category_edit: "श्रेणी संपादित करें",
  cattle_category_add: "श्रेणी जोड़ें",

  // पशुधन के लिए मान्य संदेश
  cattle_itemID_required: "आइटम आईडी आवश्यक है",
  cattle_id_required: "पशुधन आईडी आवश्यक है",
  cattle_pregnancy_status_required: "गर्भावस्था की स्थिति आवश्यक है",
  cattle_name_required: "नाम आवश्यक है",
  cattle_order_quantity_required: "आर्डर मात्रा आवश्यक है",
  cattle_unit_required: "इकाई आवश्यक है",
  cattle_unit_type_required: "इकाई प्रकार आवश्यक है",
  cattle_quantity_required: "मात्रा आवश्यक है",
  cattle_purpose_required: "उद्देश्य आवश्यक है",
  cattle_treatment_medicine_required: "उपचार/दवा आवश्यक है",
  cattle_dosage_required: "मात्रा आवश्यक है",
  cattle_DrName_required: "डॉ. का नाम आवश्यक है",
  cattle_price_required: "चिकित्सा खर्च आवश्यक है",
  cattle_symptoms_required: "लक्षण आवश्यक हैं",
  cattle_tag_id_required: "टैग आईडी आवश्यक है",
  cattle_type_required: "श्रेणी आवश्यक है",
  cattle_breed_required: "नस्ल आवश्यक है",
  cattle_age_required: "उम्र आवश्यक है",
  cattle_purchase_price_required: "खरीद मूल्य आवश्यक है",
  cattle_source_required: "स्रोत आवश्यक है",
  cattle_owner_name_required: "मालिक का नाम आवश्यक है",
  cattle_owner_id_required: "मालिक का आईडी आवश्यक है",
  cattle_deathReason_required: "मृत्यु का कारण आवश्यक है",
  cattle_purchaser_name_required: "खरीदार का नाम आवश्यक है",
  cattle_purchaser_id_required: "खरीदार का आईडी आवश्यक है",
  cattle_sold_price_required: "बिक्री मूल्य आवश्यक है",
  cattle_milk_quantity_required: "दूध की मात्रा आवश्यक है",

  // सभी पशुधन प्रबंधन हटाने के संदेश
  cattle_cattle_delete: "पशुधन हटाएं",
  cattle_cattle_sure: "क्या आप वाकई इस पशुधन को हटाना चाहते हैं?",
  cattle_medical_delete: "चिकित्सा जानकारी हटाएं",
  cattle_medical_sure: "क्या आप वाकई इस चिकित्सा रिपोर्ट को हटाना चाहते हैं?",
  cattle_pregnancy_delete: "गर्भावस्था रिपोर्ट हटाएं",
  cattle_pregnancy_sure:
    "क्या आप वाकई इस गर्भावस्था रिपोर्ट को हटाना चाहते हैं?",
  cattle_supplies_delete: "आपूर्ति हटाएं",
  cattle_supplies_sure: "क्या आप वाकई इस आपूर्ति को हटाना चाहते हैं?",

  cattle_usage_delete: "उपयोग हटाएं",
  cattle_usage_sure: "क्या आप वाकई इस उपयोग को हटाना चाहते हैं?",

  cattle_item_delete: "आइटम हटाएं",
  cattle_item_sure: "क्या आप वाकई इस आइटम को हटाना चाहते हैं?",
  cattle_breed_delete: "नस्ल हटाएं",
  cattle_breed_sure: "क्या आप वाकई इस नस्ल को हटाना चाहते हैं?",

  // सभी पशुधन खोज वेरिएबल
  search_cattles: "पशुधनआईडी, प्रकार, नस्ल द्वारा खोजें",
  search_cattles_medical: "पशुधन चिकित्सा जानकारी खोजें",
  search_cattles_pregnancy: "पशुधन गर्भावस्था रिपोर्ट खोजें",
  search_cattles_stock: "स्टॉक खोजें",
  search_cattles_supplies: "आइटम आपूर्ति खोजें",
  search_cattles_usage: "आइटम उपयोग खोजें",
  search_cattles_items: "पशुधन आइटम खोजें",

  // सूचनाओं की सभी स्थिर सामग्री

  notices_AddNotice: "सूचना जोड़ें",
  notices_latest_Notice: "नवीनतम सूचनाएं",
  add_image: "छवि जोड़ें",
  image_size_suggestion: "(कृपया 450px*350px के आकार की छवि अपलोड करें)",
  notices_title_required: "शीर्षक आवश्यक है",
  notices_desc_required: "विवरण आवश्यक है",
  notices_select_dropDown: "सूचना चुनें",
  notices_EditNotice: "सूचना संपादित करें",

  notices_delete: "सूचना हटाएं",
  notices_sure: "क्या आप वाकई इस सूचना को स्थायी रूप से हटाना चाहते हैं?",
  notices_not_found: "सूचनाएं अनुपस्थित",
  notices_not_click_add_notices:
    "नई सूचना जोड़ने के लिए, 'सूचना जोड़ें' पर क्लिक करें।",

  // श्रेणियों की सभी स्थिर सामग्री
  only_letters: "उप श्रेणी केवल वर्णमाला के अक्षरों से मिलकर हो सकती है",
  categories_AddCategory: "उप-श्रेणी जोड़ें",
  categories_serial_number: "क्रम संख्या",
  categories_master_category: "मुख्य श्रेणी",
  categories_sub_category: "उप श्रेणी",
  categories_category_required: "मुख्य श्रेणी आवश्यक है",
  categories_sub_category_required: "उप श्रेणी आवश्यक है",
  categories_latest_Category: "नवीनतम श्रेणियाँ",
  categories_title_required: "श्रेणी नाम आवश्यक है",
  categories_EditCategory: "श्रेणी संपादित करें",
  categories_select_category: "श्रेणी चुनें",
  categories_select_amount: "राशि दर्ज करें",
  category_select_sub_category: "उप श्रेणी चुनें",
  categories_select_user_name: "उपयोगकर्ता नाम चुनें",
  category_select: "चुनें",
  categories_select_master_category: "मुख्य श्रेणी चुनें",
  donar_name_required: "दाता का नाम आवश्यक है",
  created_by_required: "द्वारा बनाया गया आवश्यक है",
  user_select_required: "उपयोगकर्ता नाम आवश्यक है",
  masterCategory_required: "श्रेणी आवश्यक है",
  subCategory_required: "कृपया एक उप श्रेणी चुनें",
  commitmentID_required: "कृपया बोली आईडी चुनें",
  amount_required: "राशि आवश्यक है",

  category_delete: "श्रेणी हटाएं",
  category_sure: "क्या आप वाकई इस श्रेणी को स्थायी रूप से हटाना चाहते हैं?",
  category_not_found: "श्रेणियाँ अनुपलब्ध",
  category_not_click_add_category: "",
  cattle_stock: "भंडार",
  cattle_supplies: "आपूर्ति",
  cattle_usage: "प्रयोग",
  cattle_items: "सामान",

  // उपयोगकर्ताओं की सभी स्थिर सामग्री
  users_AddUser: "उप-व्यवस्थापक जोड़ें",
  users_latest_User: "नवीनतम उप-व्यवस्थापक",
  users_title_required: "उपयोगकर्ता नाम आवश्यक है",
  user_only_letters:
    "उपयोगकर्ता नाम केवल वर्णमाला के अक्षरों से मिलकर होना चाहिए",
  users_mobile_required: "मोबाइल नंबर आवश्यक है",
  mobile_number_limit: "मोबाइल नंबर 10 अंकों का होना चाहिए",
  users_email_required: "ईमेल आवश्यक है",
  users_not_found: "कोई उप-व्यवस्थापक नहीं मिला",
  users_not_click_add_users:
    "नया उप-व्यवस्थापक जोड़ने के लिए, 'उप-व्यवस्थापक जोड़ें' पर क्लिक करें।",

  // आंतरिक खर्च की सभी स्थिर सामग्री
  expenses_AddExpenses: "खर्च जोड़ें",
  price_per_item: "प्रति आइटम मूल्य",
  expense_type_required: "खर्च प्रकार आवश्यक है",
  expenses_EditExpenses: "खर्च संपादित करें",
  expenses_Date: "तारीख",
  expence_description: "विवरण",
  expenses_latest_Expenses: "सभी खर्चे",
  expenses_title_required: "शीर्षक आवश्यक है",
  expenses_mobile_required: "मोबाइल नंबर आवश्यक है",
  expenses_email_required: "ईमेल आवश्यक है",
  added_by: "द्वारा जोड़ा गया",
  expenses_desc_required: "विवरण आवश्यक है",
  expence_amount_required: "राशि आवश्यक है",

  expence_delete: "खर्च हटाएं",
  expence_sure: "क्या आप वाकई इस खर्च को स्थायी रूप से हटाना चाहते हैं?",
  expence_not_found: "कोई खर्च नहीं मिला",
  expence_not_click_add_expence:
    "नया खर्च रिकॉर्ड करने के लिए, 'खर्च जोड़ें' पर क्लिक करें।",

  // वित्तीय रिपोर्ट की सभी स्थिर सामग्री

  report_AddReport: "वित्तीय रिपोर्ट",
  report_expences: "खर्चे",
  remarks_financial_donationBox: "टिप्पणियाँ",
  report_commitment: "बोली",
  reports_not_found: "कोई रिपोर्ट और विवाद नहीं मिला",
  report_donation_box: "दान पेटी संग्रह",
  finance_report_no_found:
    "चयनित समयरेखा के लिए कोई वित्तीय रिपोर्ट उपलब्ध नहीं है",
  financial_not_found: "कोई वित्तीय नहीं मिला",

  // दान की सभी स्थिर सामग्री
  donation_Donation: "दान",
  start_Time: "प्रारंभ समय",
  end_Time: "समाप्ति समय",
  is_government: "सरकारी दाता है",
  donation_Adddonation: "दान जोड़ें",
  original_amount: "मूल राशि",
  estimate_amount: "अनुमानित राशि",
  donation_select_date_time: "तिथि चुनें",
  donation_select_date: "तिथि चुनें",
  donation_donar_name_only_letters:
    "दाता का नाम केवल वर्णमाला के अक्षरों से मिलकर होना चाहिए",
  add_user_donation: "कोई उपयोगकर्ता नहीं मिला, यहाँ क्लिक करें",
  donation_delete: "दान हटाएं",
  donation_sure: "क्या आप वाकई इस दान को स्थायी रूप से हटाना चाहते हैं?",
  donation_not_found: "कोई दान नहीं मिला",
  add_user: "उपयोगकर्ता जोड़ें",
  donation_paid_not_found: "कोई भुगतान किया गया दान नहीं मिला",
  donation_not_click_add_donation:
    "स्क्रीन पर दान जोड़ने के लिए, 'दान जोड़ें' पर क्लिक करें",

  // दान पेटी की सभी स्थिर सामग्री
  DonationBox_total_collection: "कुल संग्रह:",
  DonationBox_DonationBox: "दान पेटी संग्रह",
  DonationBox_AddCollectionBox: "संग्रह जोड़ें",
  DonationBox_EditCollectionBox: "संग्रह संपादित करें",
  created_by: "द्वारा बनाया गया",
  edit_collection: "संग्रह संपादित करें",
  enter_price_manually: "राशि दर्ज करें",
  viewLogs: "लॉग देखें",
  logs: "लॉग",
  add_remarks: "टिप्पणियाँ जोड़ें",
  add_amount: "राशि जोड़ें",
  DonationBox_AddDonationBox: "दान पेटी जोड़ें",
  donation_box_desc_required: "विवरण आवश्यक है",
  donation_box_amount_required: "राशि आवश्यक है",

  logData_editedBy: "द्वारा संपादित",
  logData_createdBy: "द्वारा बनाया गया",
  logData_timeDate: "समय और तिथि",
  logData_createdAmount: "बनाई गई राशि",
  logData_editedAmount: "संपादित राशि",

  donation_box_not_found: "दान पेटियाँ अनुपलब्ध",
  donation_box_not_click_add_donation_box:
    "नई दान पेटी जोड़ने के लिए, 'संग्रह जोड़ें' पर क्लिक करें",

  // सभी बोली डेटा
  notify_user: "उपयोगकर्ता को सूचित करें",
  notify_user_content:
    "उपयोगकर्ता को नज़र रखने के लिए कृपया चेक बॉक्स को चेक करें",
  commitment_Username: "उपयोगकर्ता नाम",
  add_commitment: "बोली जोड़ें",
  edit_commitment: "बोली संपादित करें",
  commitment_end_Date: "समाप्ति तिथि",
  commitment_Amount_Due: "बकाया राशि",
  commitment_select_commitment_id: "बोली आईडी चुनें",
  commitment_select_start_date: "प्रारंभ तिथि चुनें",
  commitment_select_end_date: "समाप्ति तिथि चुनें",

  commitment_delete: "बोली हटाएं",
  commitment_sure: "क्या आप वाकई इस बोली को स्थायी रूप से हटाना चाहते हैं?",
  commitment_not_found: "कोई बोली नहीं मिली",
  commitment_not_click_add_commitment:
    "नई बोली बनाने के लिए, 'बोली जोड़ें' पर क्लिक करें",
  commitment_complete: "पूर्ण",
  commitment_partial: "आंशिक",

  // सभी सदस्यता प्राप्त उपयोगकर्ता स्थिर डेटा
  subscribed_user_add_user: "सदस्यता प्राप्त उपयोगकर्ता जोड़ें",
  subscribed_user_name: "उपयोगकर्ता नाम",
  subscribed_user_pan_cardDetail: "पैन कार्ड विवरण",
  subscribed_user_date_birth: "जन्म तिथि",
  subscribed_user_email: "ईमेल",
  subscribed_user_address: "पता",
  user_name: "उपयोगकर्ता नाम",
  subscribed_not_found: "कोई सदस्यता प्राप्त उपयोगकर्ता नहीं मिला",
  subscribed_not_click_add:
    "स्क्रीन पर सदस्यता प्राप्त उपयोगकर्ता जोड़ने के लिए, 'सदस्यता प्राप्त उपयोगकर्ता जोड़ें' पर क्लिक करें",

  // सभी रिपोर्ट और विवाद स्थिर डेटा
  report_report_against: "नाम",
  report_mobile: "मोबाइल नंबर",
  export_report: "रिपोर्ट निर्यात करें",
  report_Transaction_IDs: "लेन-देन आईडी",
  report_panding: "लंबित",
  report_re_solved: "सुलझाया गया",
  report_dispute_not_found: "कोई रिपोर्ट और विवाद नहीं मिला",

  // सभी सूचना स्थिर डेटा
  notifications: "सूचनाएं",
  notifications_Notify_Users: "उपयोगकर्ताओं को सूचित करें",
  notifications_not_found: "कोई सूचना नहीं मिली",

  // उपयोगकर्ता प्रोफ़ाइल की सभी स्थिर डेटा
  about_trust: "हमारे बारे में",
  trust_address: "पता",
  trust_user: "उपयोगकर्ता के बारे में",
  trust_location: "स्थान",
  trust_facility: "सुविधाएं",
  trust_facebookLink: "फेसबुक लिंक",
  trust_facebookLink_required: "फेसबुक लिंक आवश्यक है",
  trust_contact_number_required: "संपर्क नंबर आवश्यक है।",
  contact_number: "संपर्क नंबर",
  trust_image_certificate: "चित्र और प्रमाणपत्र",
  location: "स्थान",
  trust_prefenses: "ट्रस्ट प्राथमिकताएं",
  trust_prefenses_required: "ट्रस्ट प्राथमिकताएं आवश्यक हैं",
  latitude: "अक्षांश",
  longitude: "देशांतर",
  userProfile: "प्रोफ़ाइल",
  user_editUser: "उपयोगकर्ता संपादित करें",
  user_userRoleRequired: "उपयोगकर्ता भूमिका आवश्यक है",
  minimum_one_role_required: "कम से कम एक उपयोगकर्ता भूमिका का चयन होना चाहिए",
  password_required: "पासवर्ड आवश्यक है",
  userProfile_phone_number: "फोन नंबर",
  trust_trustType: "ट्रस्ट प्रकार",
  about_trusts: "के बारे में",
  userProfile_name: "नाम",
  trust_type_required: "ट्रस्ट प्रकार आवश्यक है",
  trust_about_required: "ट्रस्ट के बारे में आवश्यक है",
  State: "राज्य",
  City: "शहर",
  events_state_required: "राज्य आवश्यक है",
  events_city_required: "शहर आवश्यक है",
  events_location_required: "स्थान आवश्यक है",
  facilities: "ट्रस्ट सुविधाएं",
  userProfile_email_id: "ईमेल",
  userProfile_temple: "मंदिर",
  latitude: "अक्षांश",
  longitude: "देशांतर",
  longitude_required: "देशांतर आवश्यक है",
  latitude_required: "अक्षांश आवश्यक है",
  user_password: "पासवर्ड",
  user_password_old: "पुराना पासवर्ड",
  user_password_new: "नया पासवर्ड",
  edit_image: "संपादित करें",
  user_password_confirm: "पासवर्ड की पुष्टि करें",
  userProfile_trust_type: "ट्रस्ट प्रकार",
  userProfile_doc_certificate: "दस्तावेज़/प्रमाणपत्र",
  userProfile_facilities: "सुविधाएं",
  userProfile_punyarjak: "puny",
  userProfile_temple_upload_doc: "अपने मंदिर का दस्तावेज़ अपलोड करें",
  user_userRole: "उपयोगकर्ता भूमिकाएं",
  images: "चित्र",
  browse: "ब्राउज़ करें",
  update_profile: "अपडेट करें",
  name_required: "नाम आवश्यक है",
  start_time: "प्रारंभ समय",
  start_time_required: "प्रारंभ समय आवश्यक है",
  end_time: "समाप्ति समय",
  end_time_required: "समाप्ति समय आवश्यक है",

  email_invalid: "अमान्य ईमेल",
  email_required: "ईमेल आवश्यक है",
  number_type: "यह फोन नंबर नहीं लगता",
  cant_start_minus: "फोन नंबर माइनस से शुरू नहीं हो सकता",
  number_in_point: "फोन नंबर में दशमलव बिंदु नहीं हो सकता",
  number_required: "फोन नंबर आवश्यक है",
  address_required: "पता आवश्यक है",
  Temple_name_required: "मंदिर का नाम आवश्यक है",
  doc_required: "मंदिर का दस्तावेज़ आवश्यक है",

  // पुण्yarjak

  punyarjak: "पुण्यार्जक",
  add_punyarjak: "पुण्यार्जक जोड़ें",
  img_required: "छवि आवश्यक है",
  edit_punyarjak: "पुण्यार्जक संपादित करें",
  name: "नाम",
  description: "विवरण",
  punyarjak_desc_required: "विवरण आवश्यक है",
  punyarjak_delete: "पुण्यार्जक हटाएं",
  username: "उपयोगकर्ता नाम केवल वर्णमाला के अक्षरों से मिलकर होना चाहिए",
  punyarjak_sure:
    "क्या आप वाकई इस पुण्यार्जक को स्थायी रूप से हटाना चाहते हैं?",
  punyarjak_not_found: "कोई पुण्यार्जक उपयोगकर्ता अनुपस्थित",
  punyarjak_not_click_add:
    "नया पुण्यार्जक उपयोगकर्ता जोड़ने के लिए, 'पुण्यार्जक जोड़ें' पर क्लिक करें।",

  // सभी प्लेस होल्डर छवियाँ

  placeHolder_donar_name: "दाता का नाम दर्ज करें",
  placeHolder_price_per_item: "प्रति आइटम मूल्य दर्ज करें",
  placeHolder_mobile_number: "मोबाइल नंबर दर्ज करें",
  placeHolder_phone_number: "संपर्क नंबर दर्ज करें",
  placeHolder_title: "शीर्षक दर्ज करें",
  placeHolder_description: "विवरण दर्ज करें",
  placeHolder_tags: "टैग दर्ज करें",
  placeHolder_name: "नाम दर्ज करें",
  placeHolder_user_name: "उपयोगकर्ता नाम दर्ज करें",
  placeHolder_email: "ईमेल दर्ज करें",
  placeHolder_password: "पासवर्ड दर्ज करें",
  placeHolder_sub_category: "उप श्रेणी दर्ज करें",
  placeHolder_city: "शहर दर्ज करें",
  placeHolder_state: "राज्य दर्ज करें",
  placeHolder_location: "स्थान दर्ज करें",
  placeHolder_trust_facebook: "फेसबुक लिंक दर्ज करें",
  placeHolder_trust_address: "पता दर्ज करें",
  placeHolder_cattle_id: "पशुधन आईडी चुनें",
  placeHolder_calf_id: "बछड़ा आईडी चुनें",
  placeHolder_cattle_treatment_medicine: "उपचार/दवा यहाँ दर्ज करें",
  placeHolder_cattle_dosage: "मात्रा यहाँ दर्ज करें",
  placeHolder_cattle_dr_name: "डॉ. का नाम दर्ज करें",
  placeHolder_cattle_purchase_price: "मूल्य यहाँ दर्ज करें",
  placeHolder_cattle_fees: "चिकित्सा खर्च दर्ज करें",
  placeHolder_cattle_symptoms: "लक्षण दर्ज करें",
  placeHolder_cattle_pregnancy_status: "गर्भावस्था की स्थिति दर्ज करें",
  placeHolder_cattle_itemId: "आइटम आईडी चुनें",
  placeHolder_cattle_item_name: "आइटम नाम चुनें",
  placeHolder_cattle_expense_quantity: "मात्रा यहाँ दर्ज करें",
  placeHolder_cattle_unit: "मात्रा इकाई चुनें",
  placeHolder_cattle_expense_unitType: "इकाई प्रकार चुनें",
  placeHolder_cattle_purpose: "उद्देश्य दर्ज करें",
  placeHolder_cattle_item_id: "यहाँ आइटम नाम दर्ज करें",
  placeHolder_cattle_expense_current_quantity: "वर्तमान मात्रा दर्ज करें",
  placeHolder_cattle_expense_order_quantity: "आर्डर मात्रा दर्ज करें",
  placeholder_cattle_type: "पशुधन श्रेणी चुनें",
  placeHolder_cattle_breed: "पशुधन नस्ल चुनें",
  placeHolder_cattle_age: "उम्र यहाँ दर्ज करें",
  placeHolder_cattle_source: "स्रोत चुनें",
  placeHolder_cattle_owner_name: "मालिक का नाम दर्ज करें",
  placeHolder_cattle_owner_Id: "मालिक का आईडी दर्ज करें",
  placeHolder_cattle_tag_id: "टैग आईडी दर्ज करें",
  placeholder_cattle_mother_id: "मां टैग आईडी चुनें या दर्ज करें",
  placeHolder_cattle_owner_number: "मालिक का नंबर दर्ज करें",
  placeHolder_cattle_death_reason: "मृत्यु का कारण यहाँ दर्ज करें",
  placeHolder_cattle_purchaser_name: "खरीदार का नाम दर्ज करें",
  placeHolder_cattle_purchaser_number: "खरीदार का नंबर दर्ज करें",
  placeHolder_cattle_purchaser_id: "खरीदार का आईडी दर्ज करें",
  placeHolder_cattle_sold_price: "बिक्री मूल्य दर्ज करें",
  placeHolder_cattle_milk_quantity: "दूध की मात्रा लीटर में दर्ज करें",
  placeHolder_expense_type: "खर्च प्रकार चुनें",
  // सभी खोज स्थिर कुंजी
  search_notification: "सभी सूचनाएं खोजें",
  search_subscribe_user: "सभी सदस्यता प्राप्त उपयोगकर्ता खोजें",
  search_news: "सभी समाचार खोजें",
  search_events: "सभी कार्यक्रम खोजें",
  search_notices: "सभी सूचनाएं खोजें",
  search_categories: "सभी श्रेणियाँ खोजें",
  search_users: "सभी उपयोगकर्ता खोजें",
  search_disputes: "सभी विवाद खोजें",
  search_expenses: "सभी खर्चे खोजें",
  search_financial_reports: "सभी वित्तीय रिपोर्ट खोजें",
  search_donation: "सभी दान खोजें",
  search_punyarjak: "सभी पुण्यार्जक खोजें",
  search_donation_box_collection: "सभी दान पेटी संग्रह खोजें",
  search_commitment: "सभी बोलीयें खोजें",

  // All static content of Dharmshala
  dharmshala_floors_registered: "मंजिल",
  dharmshala_bookings_registered: "बुकिंग",
  dharmshala_bookings_requested: "बुकिंग अनुरोध",
  dharmshala_booking_delete: "बुकिंग हटायें",
  dharmshala_booking_delete_sure: "क्या आप इस बुकिंग को हटाना चाहते हैं?",
  roomtypes_available: "उपलब्ध कक्ष प्रकार",
  total_rooms_available: "उपलब्ध कक्ष",
  total_bookings: "कुल बुकिंग",
  dharmshala_management: "धर्मशाला",
  dharmshala_registered: "उपलब्ध धर्मशालायें",
  building_registered: "भवन",
  roomtype: "कक्ष प्रकार",
  capacity: "क्षमता",
  price: "राशि",
  dharmshala_roomtypes: "कक्ष प्रकार",
  dharmshala_roomtype_add: "कक्ष प्रकार जोड़ें",
  loading: "Loading",
  error_loading_room_types: "कक्ष प्रकार नहीं मिला",
  roomtype_name: "नाम",
  dharmshala_roomtype_description: "विवरण",
  dharmshala_roomtype_capacity: "क्षमता",
  dharmshala_roomtype_price: "राशि",
  placeHolder_dharmshala_roomtype_name: "नाम भरें",
  placeHolder_dharmshala_roomtype_description: "विवरण भरें",
  placeHolder_dharmshala_roomtype_capacity: "क्षमता भरें",
  placeHolder_dharmshala_roomtype_price: "राशि भरें",
  placeHolder_dharmshala_room_number: "कक्ष नंबर भरें",
  dharmshala_roomtype_name_required: "कक्ष प्रकार भरना ज़रूरी है",
  dharmshala_roomtype_description_required: "कक्ष प्रकार विवरण भरना ज़रूरी है",
  dharmshala_roomtype_capacity_required: "कक्ष प्रकार क्षमता भरना ज़रूरी है",
  dharmshala_roomtype_price_required: "कक्ष प्रकार राशि भरना ज़रूरी है",
  dharmshala_roomtype_delete: "कक्ष प्रकार हटायें",
  dharmshala_roomtype_delete_sure: "क्या आप ये कक्ष प्रकार हटाना चाहते हैं?",
  roomtype_add: "कक्ष प्रकार जोड़ें",
  dharmshala_add: "धर्मशाला जोड़ें",
  building_add: "भवन जोड़ें",
  dharmshala_name: "नाम",
  dharmshala_description: "विवरण",
  dharmshala_location: "स्थान",
  building_name: "नाम",
  building_description: "विवरण",
  building_location: "स्थान",
  dharmshala_edit_dharmshala: "धर्मशाला जानकारी को बदलें",
  dharmshala_room_edit_dharmshala: "कक्ष जानकारी को बदलें",
  dharmshala_edit_roomtype: "कक्ष प्रकार जानकारी बदलें",
  dharmshala_edit_building: "भवन जानकारी बदलें",
  dharmshala_delete: "धर्मशाला हटायें",
  dharmshala_delete_sure: "क्या आप धर्मशाला हटाना चाहते हैं?",
  dharmshala_building_delete: "भवन हटायें",
  dharmshala_building_delete_sure: "क्या आप भवन को हटाना चाहते हैं?",
  dharmshala_booking_add: "बुक",
  dharmshala_booking_calendar: "बुकिंग कैलेंडर",
  dharmshala_floor_add: "मंजिल जोड़ें",
  dharmshala_floor_name: "नाम",
  dharmshala_floor_description: "विवरण",
  dharmshala_floor_location: "स्थान",
  dharmshala_floor_edit_dharmshala: "मंजिल जानकारी बदलें",
  dharmshala_floor_delete: "मंजिल हटायें",
  dharmshala_floor_delete_sure: "क्या आप इस मंजिल को हटाना चाहते हैं?",

  dharmshala_booking_id: "बुकिंग आईडी",
  dharmshala_booking_start_date: "बुकिंग प्रारंभ तिथि",
  dharmshala_booking_end_date: "बुकिंग अंतिम तिथि",
  dharmshala_booking_count: "संख्या",
  dharmshala_booking_status: "स्थिति",
  dharmshala_booking_early_check_in: "समय के पहले चेक इन",
  dharmshala_booking_late_check_out: "समय के बाद चेक आउट",
  view_past_requests: "पुराने बुकिंग अनुरोध",
  view_upcoming_requests: "नये बुकिंग अनुरोध",
  booking_room_delete: "कक्ष हटायें?",
  booking_room_delete_sure: "क्या आप इस कक्ष को हटाना चाहते हैं?",
  booking_room_deleted: "कक्ष हटाया गया",
  booking_room_deleted_message: "कक्ष सफलतापूर्वक हटाया गया",
  requested: "अनुरोधित",
  accepted: "स्वीकृत",
  reserved: "आरक्षित",
  confirmed: "पुष्टित",
  checkedIn: "चेक इन हो गया",
  completed: "समाप्त",
  cancelled: "रद्द किया गया",
  maintenance: "Maintenance",
  cancel_booking: "बुकिंग रद्द करें?",
  cancel_booking_sure: "क्या आप वाकई इस बुकिंग को रद्द करना चाहते हैं?",
  undo_cancel: "नहीं, वापस जाएं",
  confirm_cancel: "हाँ, रद्द करें",

  dharmshala_rooms_registered: "कक्ष",
  dharmshala_room_add: "कक्ष जोड़ें",
  dharmshala_room_number: "कक्ष संख्या",
  dharmshala_room_room_type: "कक्ष प्रकार चुनें",
  dharmshala_room_description: "विवरण",
  dharmshala_room_location: "स्थान",
  dharmshala_room_edit: "कक्ष संपादित करें",
  dharmshala_room_delete: "कक्ष हटाएं",
  dharmshala_room_delete_sure: "क्या आप वाकई इस कक्ष को हटाना चाहते हैं?",
  room_number: "कक्ष संख्या",
  directBookingAvailable: "सीधी बुकिंग उपलब्ध",
  room_type: "कक्ष प्रकार",
  Overview: "सारांश",
  Bookings: "बुकिंग",
  Buildings: "भवन",
  Feedback: "प्रतिक्रियायें",
  "Room Types": "कक्ष प्रकार",
  mode_of_payment: "पेमेंट का माध्यम",
  bank_name: "बैंक का नाम",
  cheque_no: "चेक नंबर",
  cheque_date: "चेक दिनांक",
  cheque_status: "चेक स्थिति",
  bank_narration: "बैंक भाषा",
};
